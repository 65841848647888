import './LoginSignUpModal.scss';

import { CheckCircle } from '@phosphor-icons/react';
import { Button, useGA4 } from '@travelwin/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRevalidator } from 'react-router-dom';

import { ANALYTICS_EVENT_TYPES, ACTION_VIA_SOURCE } from '../../analytics';
import ForgottenPassword from '../ForgottenPassword';
import Login from '../Login';
import Modal from '../Modal';
import Register from '../Register';

interface Props {
  on?: boolean;
  afterAuthPath?: string;
  toggle: () => void;
  handleCompleted: () => void;
  defaultActiveTab?: 'login' | 'signup';
  initiator?: ACTION_VIA_SOURCE.FREE_TRIAL;
}

const LoginSignUpModal = ({
  handleCompleted,
  on,
  afterAuthPath,
  toggle,
  defaultActiveTab = 'signup',
  initiator,
}: Props) => {
  const className = 'c-LoginSignUpModal';
  const [showPasswordChangedMessage, setShowPasswordChangedMessage] =
    useState(false);
  const [activeTab, setActiveTab] = useState<
    'login' | 'signup' | 'forgotten_password'
  >(defaultActiveTab);
  const { t } = useTranslation();
  const { trackEvent } = useGA4();
  const revalidator = useRevalidator();

  const onComplete = () => {
    revalidator.revalidate();
    handleCompleted();
  };

  const renderSwitchTabButton = () => {
    if (activeTab === 'forgotten_password') return;
    return (
      <div className={`${className}__switch-tab-button`}>
        <Button
          data-testid="button-switch-tab"
          type="submit"
          variant="secondary"
          size="small"
          onClick={() =>
            setActiveTab(activeTab === 'login' ? 'signup' : 'login')
          }
        >
          {activeTab === 'login'
            ? t('login.sign_up_btn', 'Sign Up')
            : t('login.login_btn', 'Log In')}
        </Button>
      </div>
    );
  };

  const renderLogin = () => (
    <Login
      handleCompleted={onComplete}
      goToForgottenPassword={() => {
        setActiveTab('forgotten_password');
        trackEvent(ANALYTICS_EVENT_TYPES.ForgotPassword);
      }}
      afterAuthPath={afterAuthPath}
      message={
        showPasswordChangedMessage && (
          <div className={`${className}__password-changed-alert-card`}>
            <div className={`${className}__password-changed-icon`}>
              <CheckCircle size={32} weight="bold" />
            </div>
            <div
              className={`${className}__password-changed-text`}
              data-testid="password-changed-message"
            >
              {t(
                'auth.password_change_success_msg',
                'Your password was changed successfully',
              )}
            </div>
          </div>
        )
      }
    />
  );

  const renderSignUp = () => (
    <Register
      handleCompleted={onComplete}
      afterAuthPath={afterAuthPath}
      initiator={initiator}
    />
  );

  const renderForgottenPassword = () => (
    <ForgottenPassword
      handleCompleted={() => {
        setShowPasswordChangedMessage(true);
        setActiveTab('login');
      }}
    />
  );

  const onCloseButton = () => toggle();

  useEffect(() => {
    setActiveTab(defaultActiveTab);
    setShowPasswordChangedMessage(false);
  }, [on, defaultActiveTab]);

  return (
    <Modal
      toggle={onCloseButton}
      on={on}
      fullScreen
      headerComponent={renderSwitchTabButton()}
    >
      <div className={className}>
        {(() => {
          switch (activeTab) {
            case 'login':
              return renderLogin();
            case 'signup':
              return renderSignUp();
            case 'forgotten_password':
              return renderForgottenPassword();
          }
        })()}
      </div>
    </Modal>
  );
};

export default LoginSignUpModal;
