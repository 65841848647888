import './Login.scss';

import { Capacitor } from '@capacitor/core';
import {
  Button,
  Input,
  Spinner,
  Toast,
  useConfig,
  useGA4,
} from '@travelwin/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Formik, FormikProps } from 'formik';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useUser } from '../../context/User';
import { EmailVerification } from '../EmailVerification';
import FederatedLoginButton from '../FederatedLoginButton/FederatedLoginButton';
import { TermsDisclaimer } from '../TermsDisclaimer';
import {
  ANALYTICS_EVENT_TYPES,
  LoginEventData,
  useAnalytics,
} from '../../analytics';

function getErrorCode(error: unknown) {
  if (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    typeof error.code === 'string'
  ) {
    return error.code;
  }

  return '';
}

interface Props {
  message?: ReactNode;
  hideRegister?: boolean;
  afterAuthPath?: string;
  handleCompleted: () => void;
  goToForgottenPassword: () => void;
}

const initialValues = {
  email: '',
  password: '',
};

type LoginValues = typeof initialValues;

const className = 'c-Login';

const Login = ({
  message,
  afterAuthPath,
  handleCompleted,
  goToForgottenPassword,
}: Props) => {
  const [usersEmail, setUsersEmail] = useState('');
  const [isShowVerification, setIsShowVerification] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const { signIn } = useUser();
  const { trackEvent } = useGA4();
  const { COGNITO_OAUTH_DOMAIN } = useConfig();

  const analyticsContext = useAnalytics();

  const userSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(
        t('login.invalid_email_error', 'Please enter a valid email address.'),
      )
      .max(
        128,
        t('form.error.maxLength', "Can't be longer than {{maxLength}}", {
          maxLength: 128,
        }),
      )
      .required(
        t('login.email_required_error', 'An email address is required.'),
      ),
    password: yup
      .string()
      .max(
        256,
        t(
          'form.error.passwordMaxLength',
          "Your password can't be longer than {{maxLength}}",
          { maxLength: 256 },
        ),
      )
      .required(t('login.password_required_error', 'A password is required.')),
  });

  const hasError = (
    props: FormikProps<LoginValues>,
    key: keyof LoginValues,
  ) => {
    return !!(props.errors[key] && props.touched[key]);
  };

  const showError = (
    props: FormikProps<LoginValues>,
    key: keyof LoginValues,
  ) => {
    return hasError(props, key) ? props.errors[key] : '';
  };

  const showPasswordError = (props: FormikProps<LoginValues>) => {
    if (error || showError(props, 'password')) {
      return (
        <Toast error>
          <div>{showError(props, 'password')}</div>
          <div>{error ? error : null}</div>
        </Toast>
      );
    } else {
      return null;
    }
  };

  const loginUser = async ({ email, password }: LoginValues) => {
    try {
      if (Capacitor.getPlatform() === 'ios') {
        SavePassword.promptDialog({
          username: email,
          password,
        }).catch((err) => {
          throw new Error('iOS SavePassword.promptDialog failure', err.message);
        });
      }

      setIsSigningIn(true);

      await signIn(email.trim(), password);

      handleCompleted();

      const trackingData: LoginEventData = {
        method: 'Email',
      };

      analyticsContext?.trackLogin(trackingData);
    } catch (error) {
      const code = getErrorCode(error);

      switch (code) {
        case 'UserNotConfirmedException':
          setIsShowVerification(true);
          setUsersEmail(email.toLowerCase());
          setError('');
          trackEvent(ANALYTICS_EVENT_TYPES.LoginNotVerified);
          break;
        case 'NotAuthorizedException':
          setError(
            t(
              'login.invalid_credentials_error',
              'The email or password you entered is incorrect. Please check and try again.',
            ),
          );
          trackEvent(ANALYTICS_EVENT_TYPES.LoginInvalidCreds, {
            event_label: 'Error: Invalid credentials',
          });
          break;
        default:
          setError(
            t('error.generic', 'An error has occurred, please try again later'),
          );
          trackEvent(ANALYTICS_EVENT_TYPES.LoginInvalidCreds, {
            event_label: 'Error: Unknown authorization error',
          });
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  if (isShowVerification)
    return (
      <EmailVerification
        isSigningIn={isSigningIn}
        onSuccess={handleCompleted}
        email={usersEmail}
      />
    );

  return (
    <div className={className}>
      <h2 className={`${className}__title`} data-testid="h2-welcome-back">
        {t('login.title', 'Welcome back')}
      </h2>

      <Formik
        initialValues={initialValues}
        validationSchema={userSchema}
        onSubmit={loginUser}
      >
        {(props) => (
          <form className={`${className}__form`} onSubmit={props.handleSubmit}>
            {message}
            <div className={`${className}__container`}>
              <div className={`${className}__email`}>
                <Input
                  name="email"
                  label={t('my_account.form_email_label', 'Email')}
                  placeholder={t('my_account.form_email_label', 'Email')}
                  autoComplete="email"
                  data-testid="input-login-email"
                  error={!!props.errors.email}
                  onChange={props.handleChange}
                  onBlur={() => {
                    props.setFieldTouched(
                      'email',
                      !!props.touched.email || !!props.values.email,
                    );
                  }}
                  value={props.values.email}
                />
                {showError(props, 'email') && (
                  <Toast error> {showError(props, 'email')}</Toast>
                )}
              </div>
              <div className={`${className}__password`}>
                <Input
                  name="password"
                  label={t('my_account.form_password_label', 'Password')}
                  placeholder={t('my_account.form_password_label', 'Password')}
                  type="password"
                  autoComplete="current-password"
                  data-testid="input-login-password"
                  error={!!props.errors.password}
                  onChange={props.handleChange}
                  onBlur={() => {
                    props.setFieldTouched(
                      'password',
                      !!props.touched.password || !!props.values.password,
                    );
                  }}
                  value={props.values.password}
                  addEyeIcon
                />
                {showPasswordError(props)}
                <div className={`${className}__link-container`}>
                  <Button
                    data-testid="button-forgotten-password"
                    variant="text"
                    onClick={() => goToForgottenPassword()}
                  >
                    {t('form.forgot_password_label', 'Forgot Your Password?')}
                  </Button>
                </div>
              </div>
            </div>
            <div className={`${className}__submit`}>
              <Button
                disabled={
                  Object.keys(props.errors).length > 0 ||
                  (!props.dirty && !props.isSubmitting) ||
                  isSigningIn
                }
                type="submit"
                data-testid="button-login"
                fluid
              >
                {t('login.login_btn', 'Log In')}
                {isSigningIn && (
                  <>
                    {' '}
                    <Spinner size={12} color="white" />
                  </>
                )}
              </Button>
              {COGNITO_OAUTH_DOMAIN && (
                <>
                  <FederatedLoginButton afterAuthPath={afterAuthPath} />
                  <TermsDisclaimer />
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
