import './ForgottenPassword.scss';

import { Button, Input, Toast } from '@travelwin/core';
import { Formik, FormikProps } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useUser } from '../../context/User';
import { captureError } from '../../monitoring/sentry';
import { getUserValidator } from '../../validation/userValidator';

const initialEmail = {
  email: '',
};

type EmailValues = typeof initialEmail;

const initialPass = {
  password: '',
  confirmPassword: '',
  verification: '',
};

type VerificationValues = typeof initialPass;

const className = 'c-ForgottenPassword';

interface Props {
  handleCompleted: () => void;
}

const ForgottenPassword = ({ handleCompleted }: Props) => {
  const [showEnterCode, setShowEnterCode] = useState(false);
  const [usersEmail, setUsersEmail] = useState('');
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const { forgotPassword, forgotPasswordSubmit } = useUser();

  const userValidator = getUserValidator(t);
  const passSchema = yup.object().shape({
    verification: yup
      .string()
      .required(
        t(
          'form.verification_code_required_error',
          'Your verification code is required.',
        ),
      )
      .min(
        6,
        t(
          'form.too_short_error',
          'Your code should be 6 digits, please refer to your email and check that it is correct.',
        ),
      ),
    password: userValidator.password,
    confirmPassword: userValidator.confirmPassword,
  });

  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .email(
        t('login.invalid_email_error', 'Please enter a valid email address.'),
      )
      .required(
        t('login.email_required_error', 'An email address is required.'),
      ),
  });
  const hasError = <Values,>(props: FormikProps<Values>, key: keyof Values) => {
    return !!(props.errors[key] && props.touched[key]);
  };

  const handleError = (err: any): void => {
    switch (err.code) {
      case 'UserNotFoundException':
      case 'InvalidParameterException':
        setError(
          t(
            'forgotPassword.userNotFoundException',
            `We're sorry. We weren't able to identify you given the information provided.`,
          ),
        );
        break;
      case 'LimitExceededException':
        setError(
          t(
            'forgotPassword.limitExceededException',
            'Too many login attempts made, please wait before trying again.',
          ),
        );
        break;
      case 'CodeMismatchException':
        setError(
          t(
            'form.incorrect_code_entered_error',
            'Your verification code was incorrect, please check again.',
          ),
        );
        break;
      default:
        setError(
          t('error.generic', 'An error has occurred, please try again later'),
        );
        captureError(err);
    }
  };

  const resetPassword = async ({ email }: EmailValues) => {
    try {
      setError('');
      await forgotPassword(email);

      setUsersEmail(email);
      setShowEnterCode(true);
    } catch (error) {
      handleError(error);
    }
  };

  const forgottenPasswordSubmit = async ({
    verification,
    password,
  }: VerificationValues) => {
    try {
      setError('');
      await forgotPasswordSubmit(usersEmail, verification, password);
      handleCompleted();
    } catch (error) {
      handleError(error);
    }
  };

  const renderResetForm = () => (
    <Formik
      initialValues={initialEmail}
      validationSchema={emailSchema}
      onSubmit={resetPassword}
    >
      {(props) => (
        <form className={`${className}__form`} onSubmit={props.handleSubmit}>
          <div
            className={`${className}__page-title`}
            data-testid="title-forgotten-password"
          >
            {t('form.forgot_password_label', 'Forgot Your Password?')}
          </div>
          <div className={`${className}__page-text`}>
            <p>
              {t(
                'forgot_password.muted_text1',
                'We will send a code to your registered email to reset your password.',
              )}
            </p>
            <br />
          </div>
          <Input
            name="email"
            data-testid="input-forgotten-password-email"
            label={t('my_account.form_email_label', 'Email')}
            autoComplete="email"
            placeholder={t(
              'my_account.form_email_placeholder',
              'Email address',
            )}
            error={!!props.errors.email}
            onChange={props.handleChange}
            onBlur={() => props.setFieldTouched('email', true)}
            value={props.values.email}
          />
          {hasError(props, 'email') ? (
            <Toast error>{props.errors.email}</Toast>
          ) : null}
          {error ? <Toast error>{error}</Toast> : null}
          <Button data-testid="button-verification-code" fluid type="submit">
            {t(
              'forgot_password.get_verification_code',
              'Get Verification Code',
            )}
          </Button>
        </form>
      )}
    </Formik>
  );

  const renderVerifyResetForm = () => (
    <Formik
      initialValues={initialPass}
      validationSchema={passSchema}
      onSubmit={forgottenPasswordSubmit}
    >
      {(props) => (
        <form
          className={`${className}__form`}
          onSubmit={props.handleSubmit}
          autoComplete="new-password"
        >
          <div className={`${className}__page-title`}>
            {t('form.forgot_password_label', 'Forgot Your Password?')}
          </div>
          <div className={`${className}__page-2-text`}>
            {`${t(
              'forgot_password.muted_text2',
              'We have sent an email with a verification code to',
            )} `}
            <span className={`${className}__user-email-address`}>
              {usersEmail}
            </span>
          </div>
          <div className={`${className}__page-2-text`}>
            {t(
              'forgot_password.veri_code_instructions',
              'To reset your password, please enter the 6 digit code sent to your email address.',
            )}
          </div>
          <div className={`${className}__container`}>
            <Input
              name="verification"
              data-testid="input-verification-code"
              label={t('form.verification_code_label', 'Verification Code')}
              placeholder={t(
                'form.verification_code_label_placeholder',
                'Your 6 Digit Code',
              )}
              error={!!props.errors.verification}
              onChange={props.handleChange}
              onBlur={() => props.setFieldTouched('verification', true)}
              value={props.values.verification}
            />
            {hasError(props, 'verification') ? (
              <Toast error>{props.errors.verification}</Toast>
            ) : null}
            <Input
              name="password"
              data-testid="input-new-password"
              label={t('form.new_password_label', 'New Password')}
              type="password"
              autoComplete="new-password"
              placeholder={t('form.password_placeholder', 'Password')}
              error={!!props.errors.password}
              onChange={props.handleChange}
              onBlur={() => props.setFieldTouched('password', true)}
              value={props.values.password}
              addEyeIcon
            />
            {hasError(props, 'password') ? (
              <Toast error>{props.errors.password}</Toast>
            ) : null}
            <Input
              name="confirmPassword"
              data-testid="input-confirm-password"
              label={t('form.confirm_password_label', 'Confirm Password')}
              type="password"
              autoComplete="new-password"
              placeholder={t('form.confirm_password_label', 'Confirm Password')}
              error={hasError(props, 'confirmPassword')}
              onChange={props.handleChange}
              onBlur={() => props.setFieldTouched('confirmPassword', true)}
              value={props.values.confirmPassword}
              addEyeIcon
            />
            {hasError(props, 'confirmPassword') ? (
              <Toast error>{props.errors.confirmPassword}</Toast>
            ) : null}
            {error ? <Toast error>{error}</Toast> : null}
          </div>
          <div className={`${className}__container`}>
            <Button
              data-testid="button-reset-my-password"
              disabled={
                Object.keys(props.errors).length > 0 ||
                (!props.dirty && !props.isSubmitting)
              }
              fluid
              type="submit"
            >
              {t('my_account.reset_password', 'Reset My Password')}
            </Button>
            <div className={`${className}__footer`} />
          </div>
        </form>
      )}
    </Formik>
  );

  return (
    <div className={className}>
      {showEnterCode ? renderVerifyResetForm() : renderResetForm()}
    </div>
  );
};

export default ForgottenPassword;
